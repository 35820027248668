<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 50px; margin-top: 30px">

			<a-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="18" class="col-form">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20" v-if="!loadingData">
					<a-row :gutter="[24,24]">
						<a-col :span="24">
							<a-row class="mt-0 pt-2 mb-20">

								<a-col :span="12" class="text-left pl-10">
									<a-button id="btnBordered" type="text" class="primary" v-if="(current > 0 && current <= 2) || current > 3" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp;  Back </a-button>
								</a-col>

								<!-- <a-col :span="12" class="text-right pr-10">
									<a-button id="btnBordered" type="text" class="primary" @click="skipScreen"> Skip <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
								</a-col> -->
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 2) * 100)" />
								</a-col>
							</a-row>
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row ty :gutter="[24,24]">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Select details below</strong>
															</label>
														</a-col>
													</a-row>

                                                    <a-row :gutter="[24, 24]">

														<a-col :span="24">

															<a-row :gutter="[24, 24]" class="my-0 py-0">
																<a-col :span="24" class="my-5 py-0">
																	<a-form-item class="mb-0 pb-0" label="Education Level" :colon="false">
																		<a-select 
																			v-model="quiz.levelUuid" 
																			style="width: 100%;" 
																			placeholder="Select Education Level"
																			@change="onSelectEducationLevel">
																			<a-select-option v-for="option in educationLevels"
																				:value="option.uuid"
																				:key="option.name">
																				{{ option.name }}
																			</a-select-option>
																		</a-select>
																	</a-form-item>
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="my-0 py-0">
																<a-col :span="24" class="my-5 py-0">
																	<a-form-item class="mb-0 pb-0" label="Grade" :colon="false">
																		<a-select 
																			v-model="quiz.gradeUuid" 
																			style="width: 100%;" 
																			placeholder="Select Grade">
																			<a-select-option v-for="option in grades"
																				:value="option.uuid"
																				:key="option.name">
																				{{ option.name }}
																			</a-select-option>
																		</a-select>
																	</a-form-item>
																</a-col>
															</a-row>
														

															<a-row :gutter="[24, 24]" class="my-0 py-0">
																<a-col :span="24" class="my-5 py-0">
																	<a-form-item class="mb-0 pb-0" label="Subject" :colon="false">
																		<a-select 
																			v-model="quiz.subjectUuid"
																			style="width: 100%;" 
																			placeholder="Select Subject"
																			@change="onSelectSubject">
																			<a-select-option v-for="option in subjects"
																				:value="option.uuid"
																				:key="option.name">
																				{{ option.name }}
																			</a-select-option>
																		</a-select>
																	</a-form-item>
																</a-col>
															</a-row>


															<a-row :gutter="[24, 24]">
																<a-col :span="24" class="my-5 pt-20 mt-10 text-center">
																	<a-button type="primary" v-if="quiz.subjectUuid != null && quiz.gradeUuid != null" @click="nextScreen" class="login-form-button text-white">
																		Next <a-icon type="arrow-right" theme="outlined" />
																	</a-button>
																</a-col>
															</a-row>
														</a-col>
													</a-row>

												</a-col>
											</a-row>


											<a-row type="flex" :gutter="[24,24]" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :md="12" :lg="12" :xl="{span: 12, offset: 0}" class="col-form pt-2">

													<a-row :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 18px;">
																<strong>Edit Quiz</strong>
															</label>
														</a-col>
													</a-row>

                                                    <a-row :gutter="[24, 24]">
														
														<a-col :span="24">
															<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
																<a-col :span="24" class="my-0 py-0 text-center">
																	
																	<a-button id="classIconBtn" @click="onSelectImage">
																		<img id="previewClassIcon" style="margin: 0px; width: 115px; height: 115px" v-if="quiz.thumbnail != null" :src="`${$FILE_BASE_URL}${quiz.thumbnail}`"/>
																		<span v-if="quiz.thumbnail == null">
																			<svg class="dark:text-white mb-5" style="width: 22px; color: #444444" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
																				<path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M4 18V8c0-.6.4-1 1-1h1.5l1.7-1.7c.2-.2.4-.3.7-.3h6.2c.3 0 .5.1.7.3L17.5 7H19c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H5a1 1 0 0 1-1-1Z"/>
																				<path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
																			</svg><br/>
																			Add Photo
																		</span>
																	</a-button>

																</a-col>

																<a-col :span="24" class="my-0 py-0 mt-10 text-center">
																	<small>You can either upload a photo or use one of the avatars</small>
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-input
                                                                        v-model="quiz.title"
																		type="text" 
																		placeholder="Title of the Quiz" />
																</a-col>
															</a-row>


															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-input
                                                                        v-model="quiz.questionsCount"
																		type="number" 
																		placeholder="Question count" />
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-input
                                                                        v-model="quiz.duration"
																		type="number" 
																		placeholder="Duration (Minutes)" />
																</a-col>
															</a-row>


															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0">
																<a-col :span="24" class="my-0 py-0">
																	<a-textarea 
																		:rows="4"
                                                                        v-model="quiz.instruction"
																		placeholder="Instructions" />
																</a-col>
															</a-row>

															
															<a-row :gutter="24" class="mt-20 pt-10 mb-10 pb-0">

																<a-col :span="18" class="my-0 py-0" v-if="quiz.isFree">
																	<label><strong>This quiz is free</strong></label><br/>
																	<small>Students can join and access content free</small>
																</a-col>

																<a-col :span="18" class="my-0 py-0" v-if="!quiz.isFree">
																	<label><strong>This quiz is paid</strong></label><br/>
																	<small>Students pay the set price to access this quiz</small>
																</a-col>

																<a-col :span="6" class="my-0 py-0 text-right">
																	<a-switch class="mt-10 pt-10" v-model="quiz.isFree" @change="onPriceChange($event)"/>
																</a-col>
															</a-row>

															<a-row :gutter="[24, 24]" class="mt-20 pt-10 mb-10 pb-0" v-if="!quiz.isFree">
																<a-col :span="24" class="my-0 py-0">
																	<a-input
                                                                        v-model="quiz.price"
																		type="number" 
																		placeholder="Price" />
																	<small>Price must range between 1,000/=Tzs - 5,000/=Tzs</small>
																</a-col>
															</a-row>

														
															<a-row class="mt-20 pt-20 px-20 mx-20 mb-10">
																<a-col :span="24" class="text-center pr-10">
																	<a-button :loading="quiz.loading" type="primary" v-if="quiz.title != null && quiz.thumbnail != null && quiz.duration != null && quiz.questionsCount != null && quiz.instruction != null" class="" @click="handleSubmitQuiz"> Publish <a-icon type="arrow-right" theme="outlined" /> </a-button>
																</a-col>
															</a-row>

														</a-col>
													</a-row>
													
												</a-col>
											</a-row>

		
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>

				</a-card>
			</a-col>

		</a-row>


        <div v-if="loadingData">

            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                    <a-spin class="text-primary" size="large"/>

                </a-col>

            </a-row>
        </div>


        <!-- AVATAR MODAL -->
		<a-modal v-model="quiz.avatarShowModal" :footer="null" :width="1080">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select Avatar</h6>
				</a-col>
			</a-row>

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0" v-if="!quiz.avatarLoading">
				<a-col :span="6" class="" v-for="option in avatars" :key="option.uuid">
                    <img @click="onSelectedImage(option)" id="classImg" width="100%" height="150px" :src="option.icon" alt="">
                </a-col>
			</a-row>


			<div v-if="quiz.avatarLoading">
				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 40vh">
					<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
						<a-spin class="text-primary" />
					</a-col>
				</a-row>
			</div>

		</a-modal>

	</div>
</template>


<script>
	import {DoubleBounce} from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
		},

		data() {
			return {

				loadingData: false,

				current: 0,

                grades: [],
                subjects: [],
                educationLevels: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 12,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },


                avatars: [],
				quizForm: this.$form.createForm(this, { name: 'quiz_form' }),

                quiz: {
					uuid: this.$route.params.uuid,
					title: null,
					duration: null,
					systemUuid: undefined,
                    levelUuid: undefined,
                    gradeUuid: undefined,
                    subjectUuid: undefined,
					instruction: null,
                    isFree: false,
					questionsCount: null,
					isPublished: false,
					price: null,
					thumbnail: null,
                    avatarLoading: false,
                    avatarShowModal: false,
					institution: null,
					creator: null,
                    loading: null,
					isCreate: true,
					showModal: false,
				},


				steps: [
					{
						title: 'Grade, Subject & File',
						content: 'Third-content',
					},
					{
						title: 'Title & Icon',
						content: 'Fourth-content',
					},
				],
      		}
		},
		async mounted () {

			let breadcrumbs = [
				{ title: 'Quizzes', url: `/institutions/quizzes`, isActive: false },
				{ title: 'Quizzes', url: `/institutions/my-quizzes/view/${this.$route.params.uuid}`, isActive: false },
				{ title: 'Edit Quiz', url: ``, isActive: true },
			];

			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
			
		},
		created() {
			this.getQuizDetails();
			this.getUserDetails()
		},
		methods: {

            nextScreen() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0 && this.current <= 2) {
					this.current--;
				}

				if(this.current > 3) {
					this.current--;
				}
			},


			

			async getQuizDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/quizzes/?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {

						this.quiz.uuid = null;
						this.quiz.title = response.data.data.title;
						this.quiz.duration = response.data.data.duration;
						// this.quiz.systemUuid =  response.data.data.educationSystems != null && response.data.data.educationSystems.length > 0 ? response.data.data.educationSystems[0] : [];
						this.quiz.levelUuid =  response.data.data.educationLevels != null && response.data.data.educationLevels.length > 0 ? response.data.data.educationLevels[0] : [];
						this.quiz.gradeUuid =  response.data.data.educationGrades != null && response.data.data.educationGrades.length > 0 ? response.data.data.educationGrades[0] : [];
						this.quiz.subjectUuid =  response.data.data.subjects != null && response.data.data.subjects.length > 0 ? response.data.data.subjects[0] : [];
						this.quiz.instruction = response.data.data.instruction;
						this.quiz.isFree = response.data.data.isFree;
						this.quiz.price = response.data.data.price;
						this.quiz.isPublished = response.data.data.isPublished;
						this.quiz.thumbnail = response.data.data.thumbnail.replace(this.$FILE_BASE_URL, '');;
						this.quiz.questionsCount = response.data.data.questionsCount;

						this.getGrades(response.data.data.educationLevels != null && response.data.data.educationLevels.length > 0 ? response.data.data.educationLevels[0] : null);
						this.getSubjects(response.data.data.educationLevels != null && response.data.data.educationLevels.length > 0 ? response.data.data.educationLevels[0] : null);

                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {

					console.log(error)
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getInstitutionDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.user = userDetails;

					this.quiz.creator = userDetails.uuid;
				}

				const institutionInfo = await localStorage.getItem('institution_details')

				if(institutionInfo != null) {
					let institutionDetails = JSON.parse(institutionInfo);

					this.institution = institutionDetails;

					this.quiz.institution = institutionDetails.uuid;

					this.quiz.educationSystems = [institutionDetails.educationSystems[0]];

					this.getEducationLevels(institutionDetails.educationSystems[0]);
				
				}
				
			},


			async getUserDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.user = userDetails;

					let url = `${this.$BACKEND_URL}/profiles?uuid=${userDetails.uuid}`;

					this.$AXIOS.get(url).then((response) => {

						if(response.data.data != null) {
							// this.newClass.educationSystems = response.data.data.educationSystems;
							this.getEducationLevels(response.data.data.educationSystems.length > 0 ? response.data.data.educationSystems[0] : null)
						}else {
							this.getEducationLevels(null)
						}
					
					}).catch(error => {

						this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
					
					})

				}else{
					this.getEducationLevels(null)
				}
				
				
			},


			async getEducationLevels(educationSystemUuid) {
                //educationSystemUuid

				this.loadingData = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = '';

				if(educationSystemUuid != null) {
					url = `${this.$BACKEND_URL}/education/levels?educationSystem=${educationSystemUuid}&page=${currentPage}&size=${perPage}`;
				}else{
                	url = `${this.$BACKEND_URL}/education/levels?&page=${currentPage}&size=${perPage}`;
				}

				this.$AXIOS.get(url).then((response) => {

					this.educationLevels = response.data.data;

					this.loadingData = false;

				}).catch(error => {
		
					this.loadingData = false;

					this.notify('error', error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!");
				
				})
			},


            async getGrades(levelUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/grades?educationLevel=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.grades = response.data.data;

				}).catch(error => {

		
					this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
				
				})
			},


			async getSubjects(levelUuid) {

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/education/subjects?educationLevels=${levelUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.subjects = response.data.data;

				}).catch(error => {
		
					this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
				
				})
			},


            async getAvatars(subjectUuid) {

				this.quiz.avatarLoading = true;

				const currentPage = 1;
				const perPage = 1000;

				let url = `${this.$BACKEND_URL}/avatars?subjects=${subjectUuid}&page=${currentPage}&size=${perPage}`;

				this.$AXIOS.get(url).then((response) => {

					this.avatars = response.data.data;

					this.quiz.avatarLoading = false;

				}).catch(error => {

					this.quiz.avatarLoading = false;
		
					this.notify(error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!", 'error');
				
				})
			},


            onSelectImage() {
                this.quiz.avatarLoading = true;

                this.quiz.avatarShowModal = true;

				setTimeout(() => this.quiz.avatarLoading = false, 2000);
            },

            onCreateQuiz() {

				this.quiz.uuid = null;
				this.quiz.isCreate = true;
				this.quiz.showModal = true;

				setTimeout(() => {
					this.quizForm.setFieldsValue({
						title: null,
						position: null,
						description: null,
						isCompleted: false,
					});
				}, 10);
			},


            onSelectEducationLevel(event) {
				this.grades = [];
				this.subjects = []

				console.log(event)
				this.quiz.gradeUuid = undefined;	
				this.quiz.subjectUuid = undefined;	
				
				this.getGrades(event)
				this.getSubjects(event);
			},


            onSelectGrade(uuid) {
				this.quiz.gradeUuid = uuid;
			},

            onSelectSubject(uuid) {
                this.getAvatars(uuid);
			},


            onSelectedImage(avatar) {
				this.quiz.thumbnail = avatar.iconPath;
                this.quiz.avatarShowModal = false;
            },


            onPriceChange(event) {
				if(event) {
                    this.quiz.price = 0;
				}
			},


			handleSubmitQuiz(e) {

				e.preventDefault();

				if(!this.quiz.isFree || (this.quiz.isFree && this.quiz.price >= 1000 && this.quiz.price <= 5000)) {

					this.quiz.loading = true;

					this.quiz.educationLevels = [this.quiz.levelUuid];
					this.quiz.educationGrades = [this.quiz.gradeUuid];
					this.quiz.subjects = [this.quiz.subjectUuid];

					let url = `${this.$BACKEND_URL}/quizzes/${this.$route.params.uuid}`;

					this.$AXIOS.patch(url, this.quiz).then(async(response) => {
						
						this.quiz.uuid = null;
						this.quiz.title = null;
						this.quiz.duration = null;
						this.quiz.levelUuid = undefined;
						this.quiz.gradeUuid = undefined;
						this.quiz.subjectUuid = undefined;
						this.quiz.instruction = null;
						this.quiz.isFree = false;
						this.quiz.price = null;
						this.quiz.isPublished = false;
						this.quiz.thumbnail = null;
						this.quiz.questionsCount = null;
						this.quiz.isCreate = true;
						this.quiz.showModal = false;

						this.notify('Quiz has been saved successfully', 'success')

						this.quiz.loading = false;

						setTimeout(() => this.$router.push(`/institutions/my-quizzes/view/${this.$route.params.uuid}`), 1000);

					}).catch(async(error) => {
						console.log(error)
						this.quiz.loading = false;

						this.notify(error.response.data.message ?? 'Connection errror', 'error')

						if(error.response && error.response.status == 401) {
							await localStorage.setItem("user_token", null);
							await localStorage.setItem("user_details", null)
							await localStorage.setItem("institution_details", null)

							this.$router.push(`/sign-in`);
						}
					});

				}else{
					this.notify('Price must range from 1,000/=Tzs - 5,000/=Tzs', 'error')
				}

			},


            notify(message, type) {
				notification[type]({
					message: message,
					description: '',
					placement: 'topRight',
					duration: 3,
				});
			},

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>